import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientService } from './client/client.service';
import { resources } from '@prisma/client';

export interface ResourceModelExtended extends resources {
  photo?: File;
}

@Injectable()
export class ResourcesService extends ClientService {
  public getResources(): Observable<resources[]> {
    return this.get<resources[]>(`${this.baseUrl}/api/resources`, undefined, {
      storageType: 'memory',
      ttlMS: 86400000, // 24 hours
    });
  }

  public updateResource(resource: ResourceModelExtended) {
    this.clearStorage();
    const formData: FormData = new FormData();
    formData.append('name', resource.name);
    formData.append('resourceDescription', resource.resourceDescription);
    formData.append('resourceUrl', resource.resourceUrl);
    formData.append('photoAltText', resource.photoAltText);

    if (resource.id) {
      formData.append('id', resource.id.toString());
    }
    if (resource.photo instanceof File) {
      formData.append('file', resource.photo, resource.photo.name);
    } else {
      formData.append('photoUrl', resource.photoUrl || '');
    }

    return this.http.post(`${this.baseUrl}/api/updateResource`, formData);
  }

  public deleteResource(row: any): Observable<Response> {
    this.clearStorage();
    const body = { id: row.id };
    return this.http.post<Response>(`${this.baseUrl}/api/deleteResource`, body);
  }
}
